import { useCallback, useEffect, useState } from 'react';

import { sortBy } from 'lodash';
import { useRecoilState, useResetRecoilState } from 'recoil';
import fetchConsultations from './fetchConsultations';
import Consultation from './Consultation';
import consultationsState from './store/consultationsState';
import useQueryParams from './hooks/useQueryParams';
import textSearchConsultations from './textSearchConsultations';

export default function useConsultations() {
  const [isInquiryLengthAscend, setIsInquiryLengthAscend] = useState(false);
  const [isQuestionCountAscend, setIsQuestionCountAscend] = useState(false);
  const [isAverageQuestionLengthAscend, setIsAverageQuestionLengthAscend] = useState(false);
  const [searchedIds, setSearchedIds] = useState<string[] | null>(null);

  const [consultations, setConsultations] = useRecoilState(consultationsState);
  const initializeConsultations = useResetRecoilState(consultationsState);

  const { queryType, query } = useQueryParams();

  const searched = useCallback(() => {
    if (!queryType || !query) {
      return consultations;
    }

    if (queryType === 'aka') {
      const filteredByAka = consultations.filter((c) => c.userAka.includes(query));

      return filteredByAka;
    }

    if (searchedIds === null) {
      return consultations;
    }

    const filteredByContent = consultations.filter((c) => searchedIds.includes(c.id));

    return filteredByContent;
  }, [consultations, query, queryType, searchedIds]);

  useEffect(() => {
    if (queryType === 'content' && query) {
      textSearchConsultations(query).then(setSearchedIds);
    }
  }, [query, queryType]);

  const sortLatestFirst = (cs: Consultation[]) => (
    sortBy(cs, (c) => -Number(new Date(c.createdAt)))
  );

  const loadConsultations = useCallback(async () => {
    fetchConsultations()
      .then(sortLatestFirst)
      .then(setConsultations);
  }, [setConsultations]);

  const toggleInquiryLengthOrder = useCallback(() => {
    setIsInquiryLengthAscend(!isInquiryLengthAscend);

    const ascendComparator = (a: Consultation, b: Consultation) => (
      a.inquiryLength - b.inquiryLength
    );
    const descendComparator = (a: Consultation, b: Consultation) => (
      b.inquiryLength - a.inquiryLength
    );

    const comparator = isInquiryLengthAscend ? descendComparator : ascendComparator;

    setConsultations((cs: Consultation[]) => [...cs]
      .sort(comparator));
  }, [isInquiryLengthAscend, setConsultations]);

  const toggleQuestionCountOrder = useCallback(() => {
    setIsQuestionCountAscend(!isQuestionCountAscend);

    const ascendComparator = (a: Consultation, b: Consultation) => (
      a.questionCount - b.questionCount
    );
    const descendComparator = (a: Consultation, b: Consultation) => (
      b.questionCount - a.questionCount
    );

    const comparator = isQuestionCountAscend ? descendComparator : ascendComparator;

    setConsultations((cs: Consultation[]) => [...cs]
      .sort(comparator));
  }, [isQuestionCountAscend, setConsultations]);

  const toggleAverageQuestionLengthOrder = useCallback(() => {
    setIsAverageQuestionLengthAscend(!isAverageQuestionLengthAscend);

    const ascendComparator = (a: Consultation, b: Consultation) => (
      a.averageQuestionLength - b.averageQuestionLength
    );
    const descendComparator = (a: Consultation, b: Consultation) => (
      b.averageQuestionLength - a.averageQuestionLength
    );

    const comparator = isAverageQuestionLengthAscend ? descendComparator : ascendComparator;

    setConsultations((cs: Consultation[]) => [...cs]
      .sort(comparator));
  }, [isAverageQuestionLengthAscend, setConsultations]);

  return {
    consultations: searched(),
    initializeConsultations,
    loadConsultations,
    isInquiryLengthAscend,
    isQuestionCountAscend,
    isAverageQuestionLengthAscend,
    toggleInquiryLengthOrder,
    toggleQuestionCountOrder,
    toggleAverageQuestionLengthOrder,
  };
}
