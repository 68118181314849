import { useCallback, useState } from 'react';

import WeeklyData from '../types/WeeklyData';
import fetchWeeklyData from '../service/fetchWeeklyData';

export default function useWeeklyData() {
  const [weeklyData, setWeeklyData] = useState<WeeklyData[]>([]);
  const [thisWeekData, setThisWeekData] = useState<number>();
  const [thisWeekIncreasement, setThisWeekIncreasement] = useState<string>();

  const loadWeeklyData = useCallback(async (menu: string) => {
    const data = await fetchWeeklyData(menu);
    setWeeklyData(data.weeklyData);
    setThisWeekData(data.thisWeekData);
    setThisWeekIncreasement(data.thisWeekIncreasement);
  }, []);

  const unit: Record<string, string> = {
    '추가 방문자 수': '명',
    '추가 회원가입 수': '명',
    회원가입율: '%',
    '방문자 상담시작율': '%',
    '추가질문 시작율': '%',
    '비회원의 인당 추가질문 수': '개',
    '회원의 인당 추가질문 수': '개',
    적극사용자: '%',
    '내용증명 관심도': '%',
  };

  return {
    weeklyData,
    thisWeekData,
    thisWeekIncreasement,
    unit,
    loadWeeklyData,
  };
}
