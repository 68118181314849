import { useState } from 'react';
import styled from 'styled-components';
import Button from './components/dashBoard/Button';
import palette from './styles/palette';
import MenuData from './components/dashBoard/MenuData';

const Container = styled.div`
  display: flex;
  gap: 1em;
  border: 1px solid ${palette.containerLightGrey};
  border-radius: 10px;
  padding: 16px;
  background-color: ${palette.tint9};
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 120px;
  gap: 4px;
`;

const MenuButton = styled(Button)`
  display: flex;
`;

const DataContainer = styled.div`

`;

export default function Dashboard() {
  const [selectedMenu, setSelectedMenu] = useState('전체');

  const menuList = [
    '전체',
    '추가 방문자 수',
    '추가 회원가입 수',
    '회원가입율',
    '방문자 상담시작율',
    '추가질문 시작율',
    '비회원의 인당 추가질문 수',
    '회원의 인당 추가질문 수',
    '적극사용자',
    '내용증명 관심도',
  ];

  return (
    <Container>
      <Menu>
        {menuList.map((menu) => (
          <MenuButton onClick={() => setSelectedMenu(menu)}>{menu}</MenuButton>
        ))}
      </Menu>
      <DataContainer>
        <MenuData menu={selectedMenu} />
      </DataContainer>
    </Container>
  );
}
